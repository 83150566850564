import { Alert, Button, Checkbox, Form, Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatPrice } from '../../../../utils/format';
import './TicketModal.scss';

const TicketModal = ({ visible, onClose, eventID, isEditing, editingTicket, setTickets, tickets, editingTicketId, setIsModalVisible }) => {
  const [form] = Form.useForm();

  const [nameTicket, setNameTicket] = useState('');
  const [descriptionTicket, setDescriptionTicket] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isFree, setIsFree] = useState(false);
  const [salesPeriod, setSalesPeriod] = useState('date');
  const [internalAmount, setInternalAmount] = useState(0);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (editingTicket) {
      setNameTicket(editingTicket.name);
      setDescriptionTicket(editingTicket.description);
      setInternalAmount(editingTicket.amount);
      setStartDate(editingTicket.startDate);
      setEndDate(editingTicket.endDate);
      setSalesPeriod(editingTicket.salesPeriod);
      if (editingTicket.amount === 0) {
        setIsFree(true);
      }
    }
  }, [editingTicket]);

  const handleSave = async () => {
    await handleSaveTicket();
    onClose();
  };

  const handleFreeChange = (e) => {
    setIsFree(e.target.checked);
    if (e.target.checked) {
      setInternalAmount(0);
    }
  };

  const handleSaveTicket = async () => {
    if (await validateFields()) {
      if (isEditing) {
        const updatedTickets = tickets.map((ticket) => {
          if (ticket.id === editingTicketId) {
            return {
              ...ticket,
              name: nameTicket,
              description: descriptionTicket,
              amount: internalAmount,
              starterQuestionList: ticket.starterQuestionList || [],
              questions: ticket.questions || [],
            };
          }
          return ticket;
        });
        setTickets(updatedTickets);
      } else {
        setTickets([
          ...tickets,
          {
            id: new Date().getTime(),
            name: nameTicket,
            description: descriptionTicket,
            amount: internalAmount,
            questions: [],
            starterQuestionList: [],
            new: true,
          },
        ]);
      }

      resetForm();
      setIsModalVisible(false);
    }
  };


  const resetForm = () => {
    setNameTicket("");
    setDescriptionTicket("");
    setInternalAmount(0);
    setIsFree(false)
    form.resetFields();
  };


  const handleAmountChange = (e) => {
    const value = e.target.value;
    const numericValue = value.replace(/[^0-9]/g, '');
    setInternalAmount(parseInt(numericValue, 10) || 0);
  };

  const validateFields = async () => {
    try {
      await form.validateFields();
    } catch (error) {
      return false;
    }
    const newErrors = {
      name: '',
      description: '',
      amount: ''
    };

    if (!nameTicket) newErrors.name = 'Por favor, insira o título do ingresso!';
    if (!descriptionTicket) newErrors.description = 'Por favor, insira a descrição do ingresso!';
    if (!isFree && internalAmount <= 0) newErrors.amount = 'Por favor, insira um preço valor!';
    if (nameTicket.length > 45) newErrors.name = 'O título do ingresso deve ter no máximo 45 caracteres!';
    if (nameTicket.length < 3) newErrors.name = 'O título do ingresso deve ter no mínimo 3 caracteres!';

    setErrors(newErrors);

    return !newErrors.name && !newErrors.description && !newErrors.amount;
  };


  return (
    <div style={{ backgroundColor: "red" }} className="ticket">
      <Modal
        className="ticket-modal"
        title={isEditing ? "Editar ingresso" : "Criar ingresso"}
        visible={visible}
        onOk={handleSave}
        onCancel={() => {
          onClose()
          resetForm()
        }}
        okText={isEditing ? "Salvar" : "Criar ingresso"}
        cancelText="Cancelar"
        footer={[
          <Button className='button-modal' key="cancel" danger onClick={() => {
            onClose()
            resetForm()
          }}>
            Cancelar
          </Button>,
          <Button className='button-modal' key="submit" type="primary" onClick={() => handleSaveTicket()} >
            {isEditing ? "Salvar" : "Criar ingresso"}
          </Button>
        ]}
      >
        <Alert
          className="service-fee"
          message="A taxa de serviço é adicionada ao total e apresentada ao comprador junto com o preço do ingresso"
          banner
          closable
        />

        {/* Formulário */}
        <Form form={form} layout="vertical" className='form-modal'>
          {/* Nome do ingresso */}
          <Form.Item
            hasFeedback={true}
            name="name"
            label="Título do Ingresso"
            validateDebounce={650}
            className="required"
            rules={[{ max: 45 }, { min: 3 }, { required: true }]}
          >
            <Input
              value={nameTicket}
              onChange={(e) => setNameTicket(e.target.value)}
            />

          </Form.Item>

          {/* Preço */}
          <div className="small-form">
            <Form.Item
              validateStatus={errors.amount ? 'error' : ''}
              help={errors.amount}
              style={{ margin: 0 }}
              className="required"
              label="Preço">
              <Input
                value={formatPrice(internalAmount / 100)}
                onChange={handleAmountChange}
                disabled={isFree}
              />
            </Form.Item>
            <Checkbox style={{ margin: "10px 0 20px 0" }} checked={isFree} onChange={handleFreeChange}>
              Gratuito
            </Checkbox>
          </div>

          {/* Descrição */}
          <div className="textarea-form">
            <Form.Item
              hasFeedback={true}
              name="description"
              validateDebounce={650}
              label="Descrição do Ingresso"
              className="required"
              rules={[{ max: 450 }, { min: 3 }, { required: true }]}
            >
              <Input.TextArea
                value={descriptionTicket}
                onChange={(e) => setDescriptionTicket(e.target.value)}

              />
            </Form.Item>
          </div>
        </Form>

      </Modal>
    </div>
  );
};

export default TicketModal;
