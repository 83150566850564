import { CalendarOutlined, ExclamationCircleOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  notification,
  Select,
  Skeleton,
  Switch,
  Table,
  theme,
  TimePicker
} from "antd";
import { Content } from "antd/es/layout/layout";
import Dragger from "antd/es/upload/Dragger";
import ptBR from "antd/lib/locale/pt_BR";
import dayjs from "dayjs";
import JoditEditor from "jodit-react";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { apiCreateEventLogo, apiCreateEventTicket, apiDeleteEventTicket, apiEditEvent, apiEditEventTicket, apiGetEvent, apiGetEventTickets } from "../../../../api/events";
import { ReactComponent as IncrementIcon } from "../../../../assets/images/events/increment.svg";
import { ReactComponent as Pencil } from "../../../../assets/images/events/pencil.svg";
import { ReactComponent as Question } from "../../../../assets/images/events/question.svg";
import { ReactComponent as Rocket } from "../../../../assets/images/events/rocket.svg";
import { ReactComponent as Trash } from "../../../../assets/images/events/trash.svg";
import { EVENT_CATEGORY_CHOICES, EVENT_NOMENCLATURE_CHOICES, EVENT_SUBJECT_CHOICES } from "../../../../utils/eventsSelect";
import { formatPrice } from '../../../../utils/format';
import { searchPostalCode } from "../../../../utils/searchPostalCode";
import { useIsMobileHook } from "../../../../utils/WindowHooks";
import AddQuestions from "../AddQuestions/AddQuestions";
import TicketModal from "../TicketModal/TicketModal";
import "./EventDetails.scss";


const EventDetails = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = useParams();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameClature, setNameClature] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [category, setCategory] = useState("");
  const [subject, setSubject] = useState("");
  const [fileList, setFileList] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndDateArray, setSelectedEndDateArray] = useState(null);
  const [selectedEndTimeArray, setSelectedEndTimeArray] = useState(null);

  // ADDRESS
  const [address, setAddress] = useState({
    name_comercial: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    city: '',
    state: '',
    postal_code: '',
  });

  const handleEditorChange = (newContent) => {
    setDescription(newContent);
  };

  const config = {
    readonly: false,
    placeholder: 'Compartilhe todos os detalhes do seu evento, incluindo a programação e os pontos que tornam sua produção única!',
    buttons: [
      'bold', 'italic', '|', 'ul', 'ol', '|', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',
    ],
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 471,
    toolbarAdaptive: false,
  }

  const parseJSON = (text) => {
    try {
      return JSON.parse(text);
    } catch (error) {
      return text;
    }
  };

  useEffect(() => {
    setLoading(true);

    if (id) {
      apiGetEvent(id)
        .then(async (response) => {
          const { address, ...eventDetails } = response;
          form.setFieldsValue({
            name: eventDetails.name || "",
            description: parseJSON(eventDetails.description || ""),
            category: eventDetails.category || "",
            subject: eventDetails.subject || "",
            nameClature: eventDetails.nomenclature || "",
            startDate: eventDetails.starts_at ? dayjs(eventDetails.starts_at) : null,
            endDate: eventDetails.ends_at ? dayjs(eventDetails.ends_at) : null,
            name_comercial: address?.name || '',
            street: address?.street || '',
            number: address?.number || '',
            complement: address?.complement || '',
            neighborhood: address?.neighborhood || '',
            city: address?.city || '',
            state: address?.state || '',
            postal_code: address?.postal_code || '',
          });

          // Atualiza o estado do componente
          setName(eventDetails.name || "");
          setDescription(parseJSON(eventDetails.description) || "");
          setNameClature(eventDetails.nomenclature || "");
          setCategory(eventDetails.category || "");
          setSubject(eventDetails.subject || "");
          setStartDate(eventDetails.starts_at ? dayjs(eventDetails.starts_at) : null);
          setEndDate(eventDetails.ends_at ? dayjs(eventDetails.ends_at) : null);
          const startDate = new Date(eventDetails.starts_at);
          setSelectedStartDate(dayjs(startDate));
          setSelectedStartTime(dayjs(startDate).set('hour', startDate.getHours()).set('minute', startDate.getMinutes()));
          const endDate = new Date(eventDetails.ends_at);
          setSelectedEndDateArray(dayjs(endDate));
          setSelectedEndTimeArray(dayjs(endDate).set('hour', endDate.getHours()).set('minute', endDate.getMinutes()));
          setAddress({
            name_comercial: address?.name || '',
            street: address?.street || '',
            number: address?.number || '',
            complement: address?.complement || '',
            neighborhood: address?.neighborhood || '',
            city: address?.city || '',
            state: address?.state || '',
            postal_code: address?.postal_code || '',
          });
          setMapGoogle(address?.display_map_widget || false);
          setImageUrl(eventDetails.image_url || '');
          setLoading(false);

          const ticketsApi = await apiGetEventTickets(id);
          if (ticketsApi && ticketsApi.results) {
            const transformTickets = (tickets) => {
              return tickets.results.map(ticket => ({
                id: ticket.id,
                name: ticket.name,
                description: ticket.description,
                amount: ticket.amount,
                questions: ticket.questions.map(q => q.prompt),
                starterQuestionList: ticket.questions.map(q => {
                  const questionObject = {
                    prompt: q.prompt,
                    type: q.type
                  };

                  // Adiciona os values se o tipo for RADIO ou SELECT
                  if (q.type === 'RADIO' || q.type === 'LIST') {
                    questionObject.values = q.values;
                  }

                  return questionObject;
                })
              }));
            };
            const transformedTickets = transformTickets(ticketsApi);
            setTickets(transformedTickets);
          }
        })
        .catch((error) => {
          setLoading(false);
          setActiveError(true)
          notification.error({
            message: "Erro",
            description: "Erro ao os dados do evento.",
            placement: "topRight",
          });
          setErrorStatus("400");
        });
    } else {
      setLoading(false);
    }
  }, [id]);

  // ticket
  const [nameTicket, setNameTicket] = useState();
  const [descriptionTicket, setDescriptionTicket] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemsQuestions, setItemsQuestions] = useState([]);
  const [starterQuestionList, setStarterQuestionList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const formatDateForSubmission = (date) => {
    if (!date) return null;

    const year = date.year();
    const month = String(date.month() + 1).padStart(2, '0');
    const day = String(date.date()).padStart(2, '0');
    const hours = String(date.hour()).padStart(2, '0');
    const minutes = String(date.minute()).padStart(2, '0');
    const seconds = String(date.second()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };


  const handleNameClature = (value) => {
    setNameClature(value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleSubjectChange = (value) => {
    setSubject(value);
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);


  const handleStartDateChange = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    if (formattedDate && selectedTime) {
      const combined = formattedDate + 'T' + selectedTime;
      setStartDate(combined);
    }
  };


  const handleStartTimeChange = (time) => {
    const formattedTime = time.format('HH:mm');
    setSelectedTime(formattedTime);

    if (formattedTime && selectedDate) {
      const combined = selectedDate + 'T' + formattedTime;
      setStartDate(combined);
    }
  };

  const handleEndDateChange = (date) => {
    const formattedDate = date.format('YYYY-MM-DD');
    setSelectedEndDate(formattedDate);

    if (formattedDate && selectedEndTime) {
      const combined = formattedDate + 'T' + selectedEndTime;
      setEndDate(combined);
    }
  };

  const handleEndTimeChange = (time) => {
    const formattedTime = time.format('HH:mm');
    setSelectedEndTime(formattedTime);

    if (formattedTime && selectedEndDate) {
      const combined = selectedEndDate + 'T' + formattedTime;
      setEndDate(combined);
    }
  };

  const buildPayload = (imageUrl) => ({
    name,
    description: JSON.stringify(description),
    subject,
    category,
    nomenclature: nameClature,
    starts_at: startDate,
    ends_at: endDate,
    image_url: imageUrl,
    address: {
      name: address?.name_comercial,
      street: address?.street,
      number: address?.number,
      complement: !address?.complement || address?.complement.trim() === "" ? "Sem Complemento": address?.complement,
      neighborhood: address?.neighborhood,
      city: address?.city,
      state: address?.state,
      postal_code: address?.postal_code,
      display_map_widget: mapGoogle,
    }
  });


  const handleEdit = async () => {
    let currentImageUrl = imageUrl;

    setLoading(true);

    try {
      if (fileList && fileList.length > 0) {
        const file = fileList[0]?.originFileObj;

        const maxSize = 2 * 1024 * 1024;

        if (file.size > maxSize) {
          message.error("A imagem é muito grande. O tamanho máximo permitido é 2MB.");
          setLoading(false);
          return;
        }

        try {
          const logoResponse = await apiCreateEventLogo(fileList[0].originFileObj);
          currentImageUrl = logoResponse.url;
          setImageUrl(currentImageUrl);
        } catch (uploadError) {
          message.error("Erro ao enviar a imagem. Verifique o tamanho e o formato da imagem.");
          setLoading(false);
          return;
        }
      }

      const payload = buildPayload(currentImageUrl);
      await apiEditEvent(id, payload);

      for (const ticket of tickets) {
        const payloadTicket = {
          name: ticket.name,
          description: ticket.description,
          amount: Math.floor(ticket.amount),
          questions: ticket.starterQuestionList,
        };
        if (ticket.new) {
          await apiCreateEventTicket(id, payloadTicket);
        } else {
          await apiEditEventTicket(id, ticket.id, payloadTicket);
        }
      }

      message.success("Evento editado com sucesso!");
      navigate('/eventos');
      setIsEditing(false);

    } catch (error) {
      message.error("Erro ao editar o evento. Verifique os detalhes e tente novamente.");
    } finally {
      setLoading(false);
    }
  };
  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        if (!description || description.trim() === '') {
          message.error("Por favor, insira a descrição.");
          return;
        }
        if (tickets.length === 0) {
          message.error("Por favor, adicione pelo menos um ticket.");
          return;
        }
        handleEdit();
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  useEffect(() => {
    if (address?.postal_code?.length === 8) {
      checkPostalCode(address?.postal_code);
    }
  }, [address.postal_code]);

  const checkPostalCode = (number) => {
    searchPostalCode(number, (data) => {
      if (data && !data.erro) {
        const updatedAddress = {
          name: data.localidade,
          street: data.logradouro,
          number: address.number,
          complement: address.complement || "Sem complemento",
          neighborhood: data.bairro,
          city: data.localidade,
          state: data.uf,
          postal_code: data.cep,
          name_comercial: address?.name_comercial || '',
        };

        setAddress(updatedAddress);
        form.setFieldsValue(updatedAddress);
      } else {
        message.error("CEP não encontrado. Preencha manualmente.");
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddress(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // TODO TICKET
  const addQuestion = () => {
    const questionList = [...starterQuestionList, ""];
    setStarterQuestionList(questionList);
  };

  const handleSaveQuestions = () => {
    if (selectedItem && tickets.length > 0) {
      const updatedItem = { ...selectedItem, starterQuestionList };
      const updatedItems = tickets.map(item =>
        item.id === updatedItem.id ? updatedItem : item
      );
      setItemsQuestions(updatedItems);
      setTickets(updatedItems);
    }

    setIsModalQuestionsVisible(false);
  };


  const handleUploadChange = (info) => {
    const newFileList = info.fileList.slice(-1);
    setFileList(newFileList);
    setImageUrl();
    if (info.file.status === "done") {
      message.success(`${info.file.name} arquivo carregado com sucesso`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} falha no carregamento`);
    }
  };

  // TICKETS
  const [tickets, setTickets] = useState([]);
  const [isFree, setIsFree] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalQuestionsVisible, setIsModalQuestionsVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTicketId, setEditingTicketId] = useState(null);
  const [internalAmount, setInternalAmount] = useState("");
  const [editingTicket, setEditingTicket] = useState(null);

  const openModalToEdit = (ticket) => {
    setIsEditing(true);
    setEditingTicketId(ticket.id);
    setEditingTicket(ticket)
    setNameTicket(ticket.name);
    setDescriptionTicket(ticket.description);
    setInternalAmount(ticket.amount)
    setQuestions(ticket.questions || []);
    setStarterQuestionList(ticket.starterQuestionList || []);
    setIsModalVisible(true);
  };

  const openModalQuestions = (record) => {
    setSelectedItem(record);
    setStarterQuestionList(record.starterQuestionList || []);
    setIsModalQuestionsVisible(true);
  };

  const handleDeleteTicket = async (record) => {
    try {
      const updatedTickets = tickets.filter(ticket => ticket.id !== record.id);
      const ticketToDelete = tickets.find(ticket => ticket.id === record.id);
      if (ticketToDelete && !ticketToDelete.new) {
        console.log("entrei")
        const deleteResponse = await apiDeleteEventTicket(id, ticketToDelete.id);
        if (!deleteResponse.success) {
          message.error("Falha ao deletar o ticket. Tente novamente.");
          return;
        }
      }
      setTickets(updatedTickets);
    } catch (error) {
      message.error("Erro ao deletar o ticket. Verifique os detalhes e tente novamente.");
    }
  }

  const showDeleteConfirm = (record) => {
    Modal.confirm({
      title: 'Tem certeza que deseja excluir esse ingresso?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Sim',
      okType: 'primary',
      cancelText: 'Não',
      className: 'custom-delete-modal',
      onOk() {
        handleDeleteTicket(record);
      },
      onCancel() {
        console.log('Cancelado');
      },
    });
  };


  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Valor',
      dataIndex: 'amount',
      key: 'price',
      render: (amount) => (
        <p className="ticket-price">{formatPrice(amount / 100)}</p>
      ),
      // responsive: ['md']
    },
    {
      title: '',
      key: 'action',
      render: (text, record) => (
        <div className="ticket-actions">
          <Pencil role="button" onClick={() => openModalToEdit(record)} className="button-ticket" />
          <Trash role="button" onClick={() => showDeleteConfirm(record)} className="button-ticket" />
          <button onClick={() => openModalQuestions(record)} className="button-ticket-completed">
            Configurar questinário
            <Question />
          </button>
        </div>
      ),
      responsive: ["md"],
    },
  ];

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1500);
  const handleRowClick = (record) => {
    const id = record.id;
    if (expandedRowKeys.includes(id)) {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== id));
    } else {
      setExpandedRowKeys([id]);
    }
  };

  const expandedRowRender = (record) => (
    <div className="expandid">
      <div className="ticket-actions">
        <Pencil role="button" onClick={() => openModalToEdit(record)} className="button-ticket" />
        <Trash role="button" onClick={() => showDeleteConfirm(record)} className="button-ticket" />
        <button onClick={() => openModalQuestions(record)} className="button-ticket-completed">
          Configurar questinário
          <Question />
        </button>
      </div>
    </div>
  );

  const props = {
    name: "file",
    multiple: false,
    onChange: handleUploadChange,
    maxCount: 1,
    accept: ".jpeg,.jpg,.png,.gif",
    style: { width: '100%' },
    beforeUpload: () => false,
  };

  const dateFormat = "MMM DD, YYYY";
  const timeFormat = 'HH:mm';
  moment.locale("pt-br");

  const [mapGoogle, setMapGoogle] = useState(false);

  const onChangeMap = (checked) => {
    setMapGoogle(checked);
  }

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setEditingTicket(null)
  };


  const openModal = () => {
    setIsModalVisible(true);
    setIsEditing(false);
  };

  const validateImage = (_, value) => {
    if (value && value.length > 0) {
      return Promise.resolve();
    }
    if (imageUrl) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Por favor, selecione uma imagem."));
  };


  return (
    <div className="EventDetails">
      <Helmet>
        <title>Clap Tickets - Edição de Evento</title>
      </Helmet>
      <div className="type-event-info">
        <p>{name}</p>
      </div>
      <Layout
        style={{
          padding: "0 1.25rem",
          position: "relative",
        }}
      >
        <Content
          className="content-event"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >

          {loading && <Skeleton active paragraph={{ rows: 4 }} />}
          {loading && <Skeleton active paragraph={{ rows: 4 }} />}
          {!loading && <Breadcrumb
            className="breadcrumb-title"
          >
            <Breadcrumb.Item >Meus eventos</Breadcrumb.Item>
            <Breadcrumb.Item >Edição de evento</Breadcrumb.Item>
          </Breadcrumb>}
          {!loading && <div className="title">
            <p>Informações do seu evento</p>
          </div>}
          {!loading && <div className="CreateEvent-content">
            <div className="left">
              <div className="event-form-container">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className="event-form">
                  <Form.Item
                    label="Nome do Evento"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, insira o nome do link.",
                      },
                      {max: 100}, {min: 3}
                    ]}
                  >
                    <Input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </Form.Item>
                  <div className="event-form-row">

                    <Form.Item
                      label="Categoria"
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione a categoria.",
                        },
                      ]}
                    >
                      <Select
                        value={category}
                        onChange={handleCategoryChange}
                        placeholder="Selecione a categoria"
                      >
                        {EVENT_CATEGORY_CHOICES.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Assunto"
                      name="subject"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, selecione o assunto.",
                        },
                      ]}
                    >
                      <Select
                        value={subject}
                        onChange={handleSubjectChange}
                        placeholder="Selecione o assunto"
                      >
                        {EVENT_SUBJECT_CHOICES.map((item) => (
                          <Select.Option key={item.value} value={item.value}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>


                  </div>
                  <Form.Item
                    label="Nomenclatura"
                    name="nameClature"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, selecione a nomenclatura.",
                      },
                    ]}
                  >
                    <Select
                      value={nameClature}
                      onChange={handleNameClature}
                    >
                      {EVENT_NOMENCLATURE_CHOICES.map((item) => (
                        <Select.Option key={item.value} value={item.value}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div className="image-response">
                    <Form form={form} layout="vertical">
                      <Form.Item
                        label="Imagem de Divulgação"
                        name="eventImage"
                        valuePropName="fileList"
                        rules={[{ validator: validateImage }]}
                        getValueFromEvent={({ fileList }) => fileList}
                      >
                        <Dragger
                          {...props}>
                          {imageUrl || (fileList && fileList.length > 0 && fileList[0].originFileObj) ? (
                            <img
                              src={imageUrl || URL.createObjectURL(fileList[0].originFileObj)}
                              alt="preview"
                              style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                            />
                          ) : (
                            <>
                              <p className="ant-upload-drag-icon">
                                <InboxOutlined style={{ fontSize: "32px", color: "#1890ff" }} />
                              </p>
                              <p className="ant-upload-text">Clique ou arraste a imagem aqui</p>
                              <p className="ant-upload-hint">
                                JPEG, GIF ou PNG <br /> máximo <strong>2MB</strong>
                              </p>
                            </>
                          )}
                        </Dragger>
                      </Form.Item>
                    </Form>
                  </div>
                  <div className="event-form-row date-time-pickers">
                    <div className="date-time-group">
                      <Form.Item
                        label="Início"
                        name={["startDate", "startTime"]}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!selectedStartDate || !selectedStartTime) {
                                return Promise.reject(new Error("Selecione a data de início e horário"));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <div className="date-time-field">
                          <CalendarOutlined className="custom-icon" />
                          <DatePicker
                            format={dateFormat}
                            placeholder="Data"
                            locale={ptBR}
                            value={selectedStartDate}
                            onChange={(date) => {
                              setSelectedStartDate(date);
                              handleStartDateChange(date);
                            }}
                          />
                          <TimePicker
                            use12Hours={false}
                            format={timeFormat}
                            placeholder="Horário"
                            locale={ptBR}
                            value={selectedStartTime}
                            onChange={(time) => {
                              setSelectedStartTime(time);
                              handleStartTimeChange(time);
                            }}
                          />
                        </div>
                      </Form.Item>
                    </div>

                    <div className="date-time-group">
                      <Form.Item
                        label="Término"
                        name={["endDateArray", "endTimeArray"]}
                        rules={[
                          {
                            validator: (_, value) => {
                              if (!selectedEndDateArray || !selectedEndTimeArray) {
                                return Promise.reject(new Error("Selecione a data de término e horário"));
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >

                        <div className="date-time-field">
                          <CalendarOutlined className="custom-icon" />

                          <DatePicker
                            format={dateFormat}
                            placeholder="Data"
                            locale={ptBR}
                            value={selectedEndDateArray}
                            onChange={(date) => {
                              setSelectedEndDateArray(date);
                              handleEndDateChange(date);
                            }}
                          />
                          <TimePicker
                            use12Hours={false}
                            format={timeFormat}
                            placeholder="Horário"
                            locale={ptBR}
                            value={selectedEndTimeArray}
                            onChange={(time) => {
                              setSelectedEndTimeArray(time);
                              handleEndTimeChange(time);
                            }}
                          />
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                </Form>
              </div>

              <div className="container-width" style={{ marginTop: 30 }}>
                <label className="required">Descrição</label>
                <JoditEditor
                  value={description}
                  config={config}
                  onBlur={handleEditorChange}
                  onChange={(newContent) => { }}
                />
              </div>
            </div>
            <div className="right">
              <div className="image-response">
                <Form form={form} layout="vertical">
                  <Form.Item
                    label="Imagem de Divulgação"
                    name="eventImage"
                    valuePropName="fileList"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Por favor, selecione uma imagem.",
                    //   },
                    // ]}
                    rules={[{ validator: validateImage }]}
                    getValueFromEvent={({ fileList }) => fileList}
                  >
                    <Dragger
                      {...props}>
                      {imageUrl || (fileList && fileList.length > 0 && fileList[0].originFileObj) ? (
                        <img
                          src={imageUrl || URL.createObjectURL(fileList[0].originFileObj)}
                          alt="preview"
                          style={{ width: '100%', maxHeight: '200px', objectFit: 'cover' }}
                        />
                      ) : (
                        <>
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined style={{ fontSize: "32px", color: "#1890ff" }} />
                          </p>
                          <p className="ant-upload-text">Clique ou arraste a imagem aqui</p>
                          <p className="ant-upload-hint">
                            JPEG, GIF ou PNG <br /> máximo <strong>2MB</strong>
                          </p>
                        </>
                      )}
                    </Dragger>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>}
        </Content>
        {!loading && <div className="divider"></div>}
        {!loading && <Content
          className="content-event"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="CreateEvent-content">
            <div className="left">
              <div className="event-form-location">
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  className="event-form"
                >
                  <div className="title-container">
                    <p>Onde o seu evento vai acontecer?</p>
                  </div>
                  <Form.Item
                    hasFeedback={true}
                    label="Nome do local"
                    name="name_comercial"
                    rules={[{ required: true, message: 'Por favor, insira o nome do local.' }, {min: 3}, {max: 100}]}
                  >
                    <Input
                      name="name_comercial"
                      value={address.name_comercial}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <div className="distribution-three">
                    <Form.Item
                      label="CEP"
                      name="postal_code"
                      rules={[{ required: true, message: 'Por favor, insira o CEP.' }, {max: 32}]}
                    >
                      <Input
                        name="postal_code"
                        placeholder="00000-00"
                        value={address.postal_code}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Logradouro"
                      name="street"
                      rules={[{ required: true, message: 'Por favor, insira a Logradouro.' }, {max: 100}]}
                    >
                      <Input
                        name="street"
                        value={address.street}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Número"
                      name="number"
                      rules={[{ required: true, message: 'Por favor, insira o número.' }, {max: 100}]}
                    >
                      <Input
                        name="number"
                        value={address.number}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="distribution-two">
                    <Form.Item
                      label="Bairro"
                      name="neighborhood"
                      rules={[{ required: true, message: 'Por favor, insira o bairro.' }, {max: 100}]}
                    >
                      <Input
                        name="neighborhood"
                        value={address.neighborhood}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Cidade"
                      name="city"
                      rules={[{ required: true, message: 'Por favor, insira a cidade.' }, {max: 100}]}
                    >
                      <Input
                        name="city"
                        value={address.city}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="distribution-finish">
                    <Form.Item
                      label="Estado"
                      name="state"
                      rules={[{ required: true, message: 'Por favor, insira o estado.' }]}
                    >
                      <Input
                        name="state"
                        value={address.state}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Complemento"
                      name="complement"
                      rules={[{ required: false, message: 'Por favor, insira o complemento.' }, {max: 100}]}
                    >
                      <Input
                        name="complement"
                        value={address.complement}
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </div>
                  <div className="switch-map">
                    <Switch label="Ativo" checked={mapGoogle} onChange={onChangeMap} />
                    <p>Mostrar o endereço no Google Maps</p>
                  </div>
                </Form>
              </div>
            </div>
            <div className="right" />
          </div>
        </Content>}
        {!loading && <div className="divider"></div>}
        {!loading && <Content
          className="content-event"
          style={{
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="CreateEvent-content">
            <div className="left completed">
              <div className="container-button">
                <div className="title-and-button">
                  <div className="title-container">
                    <p>Ingressos, tickets</p>
                  </div>
                  {tickets.length > 0 &&
                    <Button onClick={openModal} className="custom-btn">
                      <IncrementIcon /> Criar um ingresso
                    </Button>
                  }
                </div>

                {tickets.length === 0 && <div className="empty-tickets">
                  <Button onClick={openModal} className="custom-btn">
                    <IncrementIcon /> Criar um ingresso
                  </Button>
                </div>}
                {tickets.length > 0 && <Table
                  tableLayout="fixed"

                  columns={columns}
                  dataSource={tickets}
                  rowKey="id"
                  expandable={{
                    expandedRowRender: isMobile ? expandedRowRender : undefined,
                    expandedRowKeys: expandedRowKeys,
                    onExpand: (expanded, record) => handleRowClick(record),
                    responsive: ["xl"],
                  }}
                />}
              </div>
            </div>
          </div>
        </Content>}
        <div className="container-buttons">
          {!loading && <Button
            onClick={() => navigate('/eventos')}
            danger
            className="button-create-close"
          >
            Cancelar
          </Button>}
          <Button
            onClick={onFinish}
            loading={loading}
            type="primary"
            className="button-create-event"
          >
            <Rocket /> Editar evento
          </Button>
        </div>
      </Layout >
      <TicketModal
        eventID={id}
        visible={isModalVisible}
        onClose={handleCloseModal}
        setIsModalVisible={setIsModalVisible}
        isEditing={isEditing}
        setTickets={setTickets}
        tickets={tickets}
        setEditingTicketId={setEditingTicketId}
        editingTicketId={editingTicketId}
        editingTicket={editingTicket}
      />

      <Modal
        open={isModalQuestionsVisible}
        className="ticket-modal"
        onCancel={() => setIsModalQuestionsVisible(false)}
        footer={[
          <Button className='button-modal' key="cancel" danger onClick={() => setIsModalQuestionsVisible(false)}>
            Cancelar
          </Button>,
          <Button className='button-modal' key="submit" type="primary" onClick={() => handleSaveQuestions()}>
            Salvar perguntas
          </Button>
        ]}
      >
        <h2 className="ant-modal-title">Perguntas de cadastro na compra</h2>
        <AddQuestions
          starterQuestionList={starterQuestionList}
          setStarterQuestionList={setStarterQuestionList}
          questions={starterQuestionList}
        />
        <Button className="button-add-question" onClick={addQuestion}><PlusOutlined />Adicionar nova pergunta</Button>
      </Modal>
    </div >
  );
};

export default EventDetails;